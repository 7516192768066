var Block = {
	top: 0,
	show: 0,
	id: 0
}
var blocks = [];
var phase2 = {
	init: function(){
		$("body").on("touchstart", function(e){
			//console.log(e.touches[0].force);
		});
		this.checkHeight();

		$(document).off().on("scroll touchmove", function(){
			phase2.scrl();
		});
	},
	resize: function(){
		phase2.checkHeight();
	},
	checkHeight: function(){
		blocks = [];
		$(".a").each(function(){
			var _b = object(Block);
			_b.top 	= $(this).offset().top;
			_b.show = _b.top + $(window).height() / 3;
			_b.id 	= $(this).attr("id");
			blocks.push(_b);
		});
	},
	scrl: function(){
		var $body 	= $("body"),
			topD 	= $(document).scrollTop(),
			endD 	= topD + $(window).height(),
			stopPos = $(window).height(),
			len 	= blocks.length;
		if(topD >= stopPos){
			if(!$body.hasClass("fixbg")){
				$body.addClass("fixbg");
			}
		}else{
			if($body.hasClass("fixbg")){
				$body.removeClass("fixbg");
			}
		}
		if(topD < 10){
			$(".a").addClass("disable");
		}
		for(var i = 0; i < len; i++){
			if(i == len - 1){
				if(endD >= blocks[i].show){
					addAnimation(i)
				}
			}else{
				if(endD >= blocks[i].show){
					addAnimation(i)
				}
			}
		}
		function addAnimation(i){
			var $blk = $(".a").eq(i);
			//removeAnimation();
			if($blk.hasClass("disable")){
				//removeotherAnimation(j);
				$blk.removeClass("disable");
				setTimeout(function(){
					$blk.addClass("animated");
				},1000);
			}
			//スクロールでページビューを取得したい箇所には.a data-id=""を付けて参照する
		}
		//2021.03.05 追従ナビ
		let $fixedNav = $(".fixed_nav a");
		if( endD >= $("#footer_page_nav").offset().top ){
			if( !$fixedNav.hasClass("bottom") ){
				$fixedNav.addClass("bottom");
			}
		}else{
			if( $fixedNav.hasClass("bottom") ){
				$fixedNav.removeClass("bottom");
			}
		}
	}
}