var $v,$v_sp,winSize;
var playingMainVideo 	= false;
var isPlaying 			= false;
var main = {
	init: function(){
		$v 		= $("#video_main_pc")[0];
		$v_sp 	= $("#video_main_sp")[0];
		var path = "";
		if(isSmallScreen){
			if(videoFlag){
				//path = videoPath[1];
				setVideo();
			}else{
				$("video").remove();
				bgSlide.init();
				setTimeout(function(){
					$("body").removeClass("disable");
					$("#block_main_movie").addClass("active");
					setTimeout(function(){
						$("#block_main_movie").removeClass("disable");
					},300);
				},300);
			}
		}else{
			//path = videoPath[0];
			setVideo();
		}
		function setVideo(){
			$("#bg_slide").remove();
			//logomotion.init();
			if(!isSmallScreen){
				isPlaying = true;
				$v.play();
			}else{
				$("#block_main_movie").addClass("active");
				$("body").removeClass("disable");
				$v_sp.play();
			}
			setTimeout(function(){
				$("#block_main_movie").removeClass("disable");
				$(".btn_movie").on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend transitionEnd",function(){
					$(".btn_movie").off("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend transitionEnd");
					$("#block_main_movie").addClass("animated");
				});
			},300);
		}
		
	},
	playPCVideo: function(){
		if(playingMainVideo){
			main.stopAllVideo();
		}else{
			$v_sp.pause();
			$v.play();
		}
	},
	playSPVideo: function(){
		if(playingMainVideo){
			main.stopAllVideo();
		}else{
			$v.pause();
			$v_sp.play();
		}
	},
	stopAllVideo: function(){
		$v_sp.pause();
		$v.pause();
	},
	resize: function(){
		if(winSize === 2){
			main.playPCVideo();
		}else{
			main.playSPVideo();
		}
	}
}
function onYouTubeIframeAPIReady() {
	mainMovie.init();
}
var moviePlayer;
//var jpKey 		= "PLPhcsmW2rf5kPmKRODAzge8ni1ysKxWww";
//var globalKey 	= "PLPhcsmW2rf5ltq97GAGHWge22XHzpWWxC";
var jpKey 		= "PLPhcsmW2rf5kELQVMpfSbKSK88_NbtZI4";
var globalKey 	= "PLPhcsmW2rf5kELQVMpfSbKSK88_NbtZI4";
var movies = [];
var mainMovie = {
	id: "",
	playerID: "",
	key: "",
	current: 0,
	max: 0,
	init: function(){
		var currentLang = lang(),
			ccloadPolicy = 1,
			cclang = "ja";
		if(currentLang === "jp"){
			mainMovie.key = jpKey;
		}else{
			mainMovie.key = globalKey;
		}
		$.ajax({
			type: 'get',
			url: 'https://www.googleapis.com/youtube/v3/playlistItems', // リクエストURL
			dataType: 'json',
			data: {
			part: 'snippet', // partは必須で指定が必要とのこと。レスポンスで返してもらいたいデータをカンマ区切りで指定する。snippetがあればとりあえず動画を再生するレスポンスが受け取れる。
			playlistId: mainMovie.key, // 再生リストID
			maxResults: 20, // デフォルトは5件までしか受け取らないので、取得件数を変更
			key: "AIzaSyAi-OMBsn6BHcLg5BgEjBqzTAOV4HRl9fI"
				}
			}).done(function(response) {
				var items = response.items;
				for(var i = 0; i < items.length; i++){
					var item = items[i].snippet;
					var thisID = item.resourceId.videoId;
					movies.push(thisID);
				}
				mainMovie.max 	= movies.length;
				mainMovie.current = 0;
				nav();
			}).fail(function() {
			}
		);
		function nav(){
			/*
			$("a#btn_watchfilm_sp").on("click", function(){
				mainMovie.id 		= movies[mainMovie.current];
				mainMovie.playerID 	= $(this).attr("data-player");
				mainMovie.addMovie(mainMovie.id, mainMovie.playerID);
			});
			$("a#btn_watchfilm").on("click", function(){
				mainMovie.id 		= movies[mainMovie.current];
				mainMovie.playerID 	= $(this).attr("data-player");
				mainMovie.addMovie(mainMovie.id, mainMovie.playerID);
			});
			*/
			$("a#btn_watchfilm_sp").on("click", function(){
				mainMovie.id 		= $(this).attr("data-id");
				mainMovie.playerID 	= $(this).attr("data-player");
				mainMovie.addMovie(mainMovie.id, mainMovie.playerID);
			});
			$("a#btn_watchfilm").on("click", function(){
				mainMovie.id 		= $(this).attr("data-id");
				mainMovie.playerID 	= $(this).attr("data-player");
				mainMovie.addMovie(mainMovie.id, mainMovie.playerID);
			});
		}
	},
	addMovie: function(movieID, playerID){
		var currentLang = lang(),
			ccloadPolicy = 1,
			cclang = "ja";
		if(currentLang === "jp"){
			
		}else{
			ccloadPolicy = 0;
			cclang = "en";
		}
		let videoID = $("#btn_watchfilm").attr("data-id");
		console.log(videoID);
		moviePlayer = new YT.Player(playerID, {
			videoId 	: videoID,
			playerVars 	: {
				autohide	: 1,
				controls	: 1,
				frameborder : 0,
				rel			: 0,
				start		: 0,
				showinfo 	: 0,
				disablekb	: 1,
				wmode		: "transparent",
				autoplay	: 0,
				mute 		: 0,
				playsinline	: 1,
				enablejsapi	: 1,
				iv_load_policy: 3,
				cc_load_policy: 1,
				cc_lang_pref : cclang,
				origin: location.protocol + '//' + location.hostname + "/"
			},
			events : {
				onReady: function(e){
					mainMovie.showBlock();
				},
				onStateChange: function(e){
					
				},
				onError: function(e){
					var errorstatus = e.data;
				}
			}
		});
	},
	showBlock: function(){
		var cls,cls2,movieDiv,closeBtn;
		if(mainMovie.playerID === "mainPlayer"){
			cls 		= "show_top_movie";
			cls2 		= "active_top_movie";
			closeBtn 	= "#btn_close_topmovie";
			movieDiv 	= ".top_movie_inner";
		}
		$("body").addClass(cls);
		setTimeout(function(){
			$("body").addClass(cls2);
			setTimeout(function(){
				playingMainVideo = true;
				moviePlayer.playVideo();
			},600);
		},50);
		$(closeBtn).off().on("click", function(){
			moviePlayer.pauseVideo();
			$("body").removeClass(cls2);
			$(movieDiv).off().on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend transitionEnd",function(){
				$(movieDiv).off();
				$("body").removeClass(cls);
				var p = "#" + mainMovie.playerID;
				var $parent = $(p).parent();
				$(p).remove();
				$parent.append('<div id="' + mainMovie.playerID + '"></div>');
			});
		});
	}
}



//legacy
var bgSlideTimer;
var bgSlide = {
	count: 0,
	max : 0,
	init: function(){
		bgSlide.count = 0;
		bgSlide.max = $("#bg_slide li").length;
		bgSlideTimer = setInterval(function(){
			bgSlide.increase();
		},3000);
	},
	increase: function(){
		bgSlide.count++;
		if(bgSlide.count >= bgSlide.max){
			bgSlide.count = 0;
		}
		//console.log(bgSlide.count)
		this.move();
	},
	move: function(){
		var cls = "active-" + bgSlide.count;
		$("#bg_slide").removeClass(function(index, className) {
			return (className.match(/\active-\S+/g) || []).join(' ');
		});
		$("#bg_slide").addClass(cls);
	}
}


