var Section = {
	id: "",
	top: ""
}
var sections = [];
var common = {
	init: function(){
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		isPage = $body.attr("data-id");
		var imgarry = [];
		if(isPage === "index"){
			$("img").each(function(i){
				var src = $(this).attr("src");
				imgarry.push(src);
			})
			preload.startLoad(imgarry.length,imgarry).done(function(){
				main.init();
				common.nav();
				phase2.init();
				$("body").removeClass("disable");
				setTimeout(function(){
					common.scrl();
					phase2.scrl();
				}, 600);
			});
		}else{
			if($("#bg").length > 0){
				var img = $("#bg .bgimg").css("background-image").match( /^url\(["']?(.*?)["']?\)/i )[1];
				imgarry.push(img);
				common.checkBlock();
				preload.startLoad(imgarry.length,imgarry).done(function(){
					//下層ページ
					Ts.onComplete(function(){
						
					});
					$body.removeClass("disable");
					common.scrl();
				})
			}
			$(document).off().on("scroll touchmove", function(){
				common.scrl();
			});
			if(isPage === "products" && lang() === "en"){
				langSelect.init();
			}
		}

		var resizeCallback = function() {
			common.resize();
			if(isPage === "index"){
				phase2.resize();
			}
			resizeTimer = null;
		};
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		this.nav();
	},
	resize: function(){
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		
		if (window.matchMedia('all and (min-width : 1366px)').matches) {
			isSmallScreen = false;
		} else if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 768px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		common.checkBlock();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	nav: function(){
		let $fixednav = $(".fixed_nav a");
		$fixednav.off().on("click", function(){
			var $html 	= $("html,body"),
				ease 	= "easeInOutCubic",
				posY 	= 0;
			if( $fixednav.hasClass("bottom") ){
				//上へ
				posY = 0;
			}else{
				//下へ
				posY = $("#footer_page_nav").offset().top - $("header#global-header").height();
			}
			$html.animate({
				scrollTop : posY + "px"
			},{
				"duration" 	: 1000,
				easing: ease
			});
		});
		$("#footer_page_nav li a").off().on("click", function(){
			var link = $(this).attr("data-link");
			var l = lang();
			movePage(link);
		});
		$("#page_nav li a").off().on("click", function(){
			var link = $(this).attr("data-link");
			var l = lang();
			movePage(link);
		});
		$("#fnav li a").off().on("click", function(){
			var link = $(this).attr("data-link");
			var l = lang();
			movePage(link);
		});
		function movePage(link){
			if(link){
				$("body").addClass("disable");
				$("body").off().on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend transitionEnd",function(){
					$("body").off();
					setTimeout(function(){
						var nextpage = link;
						location.href = nextpage;
					},400);
				});
			}
		}
		var twTxt = "";
		var twUrl = "";
		$(".sns_share li a").on("click", function(){
			var indexNum = $(".sns_share li a").index(this);
			var $this = $(this);
			if($(this).hasClass("weibo")){
				shareWeibo();
			}else{
				if(indexNum == 1){
					twTxt = $this.attr("data-text");
					twUrl = $this.attr("data-link");
				}else{
					twUrl = $this.attr("data-link");
				}
				shareTxt(indexNum);
			}
		});
		$(".sns_share_sp li a").on("click", function(){
			var indexNum = $(".sns_share_sp li a").index(this);
			var $this = $(this);
			if($(this).hasClass("weibo")){
				shareWeibo();
			}else{
				if(indexNum == 1){
					twTxt = $this.attr("data-text");
					twUrl = $this.attr("data-link");
				}else{
					twUrl = $this.attr("data-link");
				}
				shareTxt(indexNum);
			}
		});
		$(".sns_share02_sp li a").on("click", function(){
			var indexNum = $(".sns_share02_sp li a").index(this);
			var $this = $(this);
			if($(this).hasClass("weibo")){
				shareWeibo();
			}else{
				if(indexNum == 1){
					twTxt = $this.attr("data-text");
					twUrl = $this.attr("data-link");
				}else{
					twUrl = $this.attr("data-link");
				}
				shareTxt(indexNum);
			}
		});
		$(".show_share").off().on("click", function(){
			//$(".sns_share").toggleClass("show");
			$(".sns_share").animate({
				height: "toggle", opacity:"toggle"
			},300)
		});
		$("h6 .select_country").off().on("click", function(){
			$(".nav_country").animate({
				height: "toggle", opacity:"toggle"
			},300)
		});
		$(".pagetop").off().on("click", function(){
			var $html 	= $("html,body"),
				ease 	= "easeInOutCubic";
			$html.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				easing: ease
			});
		});
		$("#btn_menu").off().on("click", function(){
			var $nav = $("#nav_sp"),
				$body = $("body");
			if(!$body.hasClass("shownav")){
				$body.addClass("shownav");
			}else{
				$body.removeClass("shownav");
			}
		});
		//SNS SHARE
		function shareTxt(indexNum){
			var url 	= location.href;
			if(indexNum === 0){
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(twUrl),
					'facebooksharedialog',
					'width=626,height=436');
					return false;
			}else if(indexNum === 1){
				//twitter
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(twUrl) + '&text=' + encodeURIComponent(twTxt),
					'twittersharedialog',
					'width=626,height=436');
				return false;
			}
		}
		function shareWeibo(){
			var desc = "";
			var url = 'http://service.weibo.com/share/share.php?url=' + encodeURIComponent(location.href) + '&appkey=&title=' + desc + '&pic=&ralateUid=&language=zh_cn#_loginLayer_1458840887998';
			
			window.open(
				url,
				'twittersharedialog',
				'width=626,height=436');
			return false;
		}
		$(".select_country select").on("change", function(){
			var val = $(this).val();
			var valarry 	= val.split("/");
			var selectLang = valarry[1];
			var currentLang = lang();
			if(val != ""){
				if(currentLang != selectLang){
					location.href = val;
				}
			}
		});
	},
	scrollToPos: function(id){
		var posY = $("#" + id).offset().top;
		var $html 	= $("html,body"),
			ease 	= "easeInOutCubic";
		$html.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			easing: ease
		});
	},
	checkBlock: function(){
		sections = [];
		$(".a").each(function(i){
			var _a = object(Section);
			_a.top = Math.floor($(this).offset().top + $(this).height()/2);
			sections.push(_a);
		});
		//console.log(sections)
	},
	scrl: function(){
		var $body 	= $("body"),
			topD 	= $(document).scrollTop(),
			btmD 	= topD + $(window).height(),
			len 	= sections.length,
			mainImgH = $(".ttl").height() - $("#btn_menu").height();
		//console.log(topD)
		if(isPage === "index"){
			mainImgH = $("#block_video").height() - $("#btn_menu").height();
		}
		if(topD >= 20){
			if(!$body.hasClass("bg_disable")){
				$body.addClass("bg_disable")
			}
		}else{
			if($body.hasClass("bg_disable")){
				$body.removeClass("bg_disable")
			}
		}
		if(topD >= mainImgH){
			if(!$body.hasClass("nav_black")){
				$body.addClass("nav_black")
			}
		}else{
			if($body.hasClass("nav_black")){
				$body.removeClass("nav_black")
			}
		}
		for(var i = 0; i < len; i++){
			if(btmD >= sections[i].top){
				activeBlock(i);
			}
		}
		function activeBlock(i){
			var $a = $(".a:eq(" + i + ")");
			if($a.hasClass("active")){

			}else{
				//peopleのページだけ特殊
				$a.addClass("active");
				setTimeout(function(){
					$a.addClass("animated");
				},1000);
			}
		}
		if(isPage === "voice"){
			var bgTop = $("#people.block").offset().top;
			if(btmD >= bgTop){
				if(!$("#bg").hasClass("black")){
					$("#bg").addClass("black");
				}
			}else{
				if($("#bg").hasClass("black")){
					$("#bg").removeClass("black");
				}
			}
		}
		//2021.03.05 追従ナビ
		let $fixedNav = $(".fixed_nav a");
		if( btmD >= $("#footer_page_nav").offset().top ){
			if( !$fixedNav.hasClass("bottom") ){
				$fixedNav.addClass("bottom");
			}
		}else{
			if( $fixedNav.hasClass("bottom") ){
				$fixedNav.removeClass("bottom");
			}
		}
	}
}
var lang = function(){
	var lang = "";
	var host = location.hostname;
	if(host.indexOf("honda-dct")>-1){
		var hostarry = host.split(".");
		var first = hostarry[0];
		if(first.indexOf("stg") > -1){
			lang = first.replace("stg-","");
		}else{
			lang = first;
		}
		//console.log(lang);
	}else{
		let currentLocation = location.pathname,
		locationArry 	= currentLocation.split("/");
		lang = locationArry[1];
	}
	
	return lang;
}
$(function(){
	common.init();
});