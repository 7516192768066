var userAgent 	= window.navigator.userAgent.toLowerCase();
var appVersion 	= window.navigator.appVersion.toLowerCase();
var isLegacy 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1;
var isIE10 		= appVersion.indexOf("msie 10.0") > -1;
var isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1;
var isIE8over 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1 ||  appVersion.indexOf("msie 11.0") > -1;
var isIE 		= userAgent.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/);
var isAndroid 	= navigator.userAgent.match(/Android/i);
var isIPhone 	= navigator.userAgent.match(/iPhone/i);
var isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i);
var isIPad 		= navigator.userAgent.match(/iPad/i);
var isFF 		= userAgent.indexOf("firefox")!== -1;
var isEdge 		= userAgent.indexOf("applewebkit") > -1 && userAgent.indexOf("edge") > -1;
var isMobile	= true;
var isSmallScreen = false;
var isPC = true;
var $html = document.documentElement;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
	isSP = true;
}else{
	isPC = true;
	isSP = false;
}
function iosVersion () {
	var v, versions;
	if ( /iP(hone|od|ad)/.test( navigator.platform ) ) {
		// supports iOS 2.0 and later: &lt;http://bit.ly/TJjs1V&gt;
		v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		return versions[ 0 ];
	}
	return versions;
}
//win or mac
if(navigator.userAgent.indexOf("Win") !== -1 ){
	if(isFF){
		document.documentElement.className = "os_windows firefox";
	}else{
		if(isIE){
			if(isIE10){
				document.documentElement.className = "os_windows ie10";
			}else if(isIE11){
				document.documentElement.className = "os_windows ie11";
			}
		}else if(isEdge){
			document.documentElement.className = "os_windows edge";
		}else{
			document.documentElement.className = "os_windows";
		}
	}
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	if(isFF){
		document.documentElement.className = "os_mac firefox";
	}else{
		document.documentElement.className = "os_mac";
	}
}
var isPage;
//FAQ用YouTube
var isReady = false;
var bgMovie = {};
var playing = false;
var YT;
var ytPlayer;
var ytTimer;
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//iOS10以外、Androidはvideo再生しない
var videoFlag = true;
if(isSP){
	if(isIPhone){
		var ver = iosVersion();
		if(ver < 10){
			videoFlag = false;
		}else{
			videoFlag = true;
		}
	}else{
		videoFlag = false;
	}
}else{
	videoFlag = true;
}
var isRelease = false;

var load = {
	getJson: function (url, params) {
		var defer = $.Deferred();
		var url = url;
		$.ajax({
			type: "GET",
			dataType: "json",
			url: url,
			data: params,
			success: defer.resolve,
			error: defer.reject,
		});
		return defer.promise();
	},
};
